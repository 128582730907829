import React from "react";
import Banner from "../components/Banner";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Cta from "../components/service/Cta";
import ServiceTab from "../components/ServiceTab";
import Clients from "../components/service/Clients";
import Testimonials from "../components/Testimonials";
import ProjectSection from "../components/ProjectSection";
import Faq from "../components/Faq";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

const ServiceDetail = () => {
  const banner = {
    title: `Strategic Branding Agency`,
    content:
      "We are one of the best branding agency with expertise in creating brands that resonate with your users and leave a memorable mark.",
  };

  const commonContent6 = {
    title: "Transforming Brands with <span>Digital Branding Services</span>",
    para: "In a competitive landscape where businesses vie for user attention, the absence of a strong brand presence can leave your company struggling. As a leading branding company, we offer branding services that effectively enhance your brand presence and capture customer attention.",
    img: ["what-service-image-branding.webp"],
    imageTitle: "Branding",
    imageAlt: "top branding agency in india",
    content: [
      {
        title: "Brand Strategy Development",
        text: "We set you apart by crafting a unique and compelling brand strategy that resonates with your audience.",
      },
      {
        title: "Consultation and Training",
        text: "We offer expert advice to empower your team to maximize impact and branding efforts.",
      },
      {
        title: "Website Design and Development",
        text: "We increase your web presence by creating user-friendly, functional, and visually stunning websites.",
      },
      {
        title: "Logo and Visual Identity Design",
        text: "We transform your brand by creating a visually appealing brand identity through captivating logos and strategies.",
      },
      {
        title: "Rebranding",
        text: "We provide strategic rebranding advice to transform your brand by aligning with the evolving goals.",
      },
    ],
  };

  const commonContent = {
    title: "Industries who need <span>Branding Company</span>",
    para: "In the changing digital environment, businesses require creative branding services to capture user attention, establish online interactions & foster credibility. A branding company ensures you have the innovative solutions needed to thrive in this dynamic landscape. ",
    img: ["who-needs-branding.webp"],
    imageTitle: "Branding Services",
    imageAlt: "digital branding agency",
    content: [
      {
        title: "Startups",
        text: "Companies who want to establish a memorable brand identity in the minds of their customers.",
      },
      {
        title: "E-commerce Industry",
        text: "Companies who want to stay competitive and create an exceptional online experience.",
      },
      {
        title: "Tech Companies",
        text: "Companies who want to communicate their innovative solutions to users for product adoption.",
      },
      {
        title: "Non-Profit organisations",
        text: "Companies that want to communicate their mission, create awareness, and inspire an audience to support a noble cause.",
      },
    ],
  };

  const commonContent2 = {
    title:
      "Why work with a <span class='h1-span'>Branding Company?</span>",
    para: "Collaborating with the best branding company opens doors to expert strategies, innovative creativity, and impactful branding solutions that elevate your brand's presence and influence.",
    img: ["what-are-benefits-branding.webp"],
    imageTitle: "Branding Benefits",
    imageAlt: "best branding agency",
    content: [
      {
        title: "Fresh Business Outlook",
        text: "A creative branding agency brings fresh perspectives and in-depth industry insights, assisting businesses in uncovering new growth opportunities. ",
      },
      {
        title: "Market Insights",
        text: "Branding company conducts thorough market research and analyses the insights on industry trends and customer preferences. ",
      },
      {
        title: "Long-term Growth",
        text: "A branding agency develops a strong brand identity to gain customers' trust, build brand equity, and stand out from the competitors.",
      },
      {
        title: "Competitive Analysis",
        text: "A digital branding agency researches and evaluates competitors' positioning, digital identity, etc., to identify market challenges and opportunities.",
      },
    ],
  };
  const commonContent3 = {
    title: "Why Choose Octet as your <span class='h1-span'>Best Branding Agency?</span>",
    linkText: ``,
    para: "We are a creative team of visionaries and strategic thinkers who conduct thorough market research, create tactical brand strategies, and develop brand identities. We focus on driving growth through our creative branding services.",
    img: ["why-opt-for-branding.webp"],
    imageTitle: "Branding Agency in India",
    imageAlt: "best branding company",
    content: [
      {
        title: "Tailor-made Approach",
        text: "We understand that every business has distinct needs and create unique brand strategies that align with their specific requirements. ",
      },
      {
        title: "Transparent Process",
        text: "Our transparent branding services ensure clear communication and collaboration that fosters trust and assists in making informed decisions. ",
      },
      {
        title: "Cross-Industrial Expertise",
        text: "We understand challenges, identify opportunities, and offer fresh perspectives and innovative strategies with our diverse industrial knowledge.",
      },
      {
        title: "Long-term Collaboration",
        text: "We focus on building long-term partnerships with our clients through mutual understanding, trust, and loyalty for shared goals.",
      },
    ],
  };
  const commonContent4 = {
    title: "Our Approach to <span class='h1-span'>Digital Branding Services</span>",
    para: "We believe that exceptional branding services are the cornerstone of forging lasting connections that assist us in delivering memorable brand experiences.",
    img: ["how-we-conduct-branding.webp"],
    imageTitle: "Creative Branding Process",
    imageAlt: "branding agency in india",
    content: [
      {
        title: "1. Identify",
        text: "We start by identifying the needs of your customers and the ways you have planned to fulfill them.",
      },
      {
        title: "3. Experiment",
        text: "We try our different combinations of your visual brand identity to see which works best and then finalize it.",
      },
      {
        title: "2. Ideate",
        text: "We ideate on the aspects of your visual brand identity - logo, typography, color palette, and imagery.",
      },
      {
        title: "4. Deploy",
        text: "We then launch the finalized version of your brand identity to the public.",
      },
    ],
  };
  const commonContent5 = {
    title: "Benefits of Investing in <span class='span'>Corporate Branding Services</span>",
    para: "Branding services yield significant benefits to your business by crafting a compelling identity that connects to your audience, enhances brand recognition, and drives long-term success.",
    content: [
      {
        title: "89% Customer Loyalty",
        text: "An estimated 89% of customers prefer brands that are easily recognizable and offer them a positive experience.",
      },
      {
        title: "33% Increased Revenue",
        text: "A brand that shows its consistent presence in the market has seen an increase in revenue by 33%.",
      },
      {
        title: "31%- 50% Willingness to Pay",
        text: "It is estimated that around 31% to 50% of customers are willing to pay more to the brand, which positively impacts their minds.",
      },
    ],
    desc: "*These data are from public sources; we do not claim to owe these stats.",
  };
  const cta4 = {
    title: "Boost Your Brand With Our <span class='h1-span'>Creative Branding Agency!</span>",
  };
  const cta = {
    title: "Stand Out With Our <span class='h1-span'>Creative Branding Services!</span>",
  };
  const cta5 = {
    title: "Achieve Brand Success With Top Branding Company!",
  };
  const cta2 = {
    title: "Invest in Best Branding <span class='h1-span'>Company Today!</span>",
  };
  const cta3 = {
    title: "Boost your Brand with the <span class='h1-span'>Best Branding Company!</span>",
  };
  const tabs = [
    {
      link: "/angularjs-development/",
      text: "Angular Development",
    },
    {
      link: "/branding/",
      text: "Branding",
    },
    {
      link: "/corporate-training-workshop/",
      text: "UI UX Workshops and Training",
    },
    {
      link: "/data-visualisation/",
      text: "Dashboards and Data Visualisation",
    },
    {
      link: "/design-audit/",
      text: "Design Audit ",
    },
    {
      link: "/design-systems/",
      text: "Design System Engineering",
    },
    {
      link: "/digital-transformation/",
      text: "Digital Transformation",
    },
    {
      link: "/product-development/",
      text: "Product Development",
    },
    {
      link: "/rapid-prototyping/",
      text: "Rapid Prototyping",
    },
    {
      link: "/reactjs-development/",
      text: "ReactJS Development",
    },
    {
      link: "/ui-development/",
      text: "UI Development",
    },
    {
      link: "/ui-ux-designing/",
      text: "UI UX Designing",
    },
    {
      link: "/usability-testing/",
      text: "Usability Testing",
    },
    {
      link: "/user-acquisition-consulting/",
      text: "User Acquisition Consultation",
    },
    {
      link: "/user-research/",
      text: "User Research",
    },
    {
      link: "/ux-consulting/",
      text: "UX Consultation",
    },
    {
      link: "/vuejs-development/",
      text: "VueJS Development",
    },
    {
      link: "/wordpress-development/",
      text: "WordPress Development",
    },
  ];
  const projectData = [
    {
      title: "KYSS Invoicing",
      desc: "An accounting platform for MSME's",
      Industry: "FinTech",
      link: "/project/kyss/",
      firstAlt: "UI UX Mobile Screen Of Accounting Platform",
      firstTitle: "Landing Page",
      secondAlt: "Past Invoice Details",
      secondTitle: "All Invoices",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
      ],
      images: ["kyss.webp", "kyss-2.webp"],
    },
    {
      title: "Fio",
      desc: "A D2C Fashion E-Commerce Platform",
      Industry: "Ecommerce",
      link: "/project/fio/",
      firstAlt: "creative branding services",
      firstTitle: "Landing Page",
      secondAlt: "corporate branding services",
      secondTitle: "Product List Page",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      images: ["fio-project.webp", "fio-project-2.webp"],
    },
  ];

  const Questions = [
    "How can your UI UX design promote our brand?",
    "What are the branding services that you can offer?",
    "When does your branding effort start delivering results?",
    "How do you evaluate the success of your branding effort?",
    "Do you provide post-project support?",
    "What is the cost of opting for your UI UX Branding Services?",
  ];
  const clientsTitle = "Trusted Clients of our <span class='h1-span'>Digital Branding Agency</span>";
  const faqDes = `You must have more questions regarding our branding services, processes, approach etc. Hence, in this section, we have answered some common queries asked by our clients. If you still have any doubts, feel free to <a href='/contact-us/' class='h1-span'>contact us</a>. `;

  const faqData = [
    {
      para: [
        `Being a leading UI UX design agency, we create a visually appealing and user-friendly interface that aligns with your brand and connects with your target audience.`,
        `We focus on creating thoughtful designs that resonate with your audience, establish your digital presence in the market, and distinguish you from your competitors.`,
        `Additionally, we consider offering a positive, seamless, and intuitive user experience to enhance your brand perception among users and foster customer loyalty and trust, ultimately leading to long-term business success.`,
      ],
    },
    {
      para: [
        `We offer various digital branding services tailored to meet your business requirements. Our services include-`,
      ],
      list: [
        `<span><h4 class="inline">Research & Insights:</h4></span> - We start by listening to all the stakeholders and charting out insight-driven strategies.`,
        `<span><h4 class="inline">Positioning & Proposition:</h4></span> - We brainstorm various ideas as to how we can position your brand to stand out from the competition.`,
        `<span><h4 class="inline">Brand Messaging:</h4></span> -  We define clear rules and guidelines for brand voice & tone and help in its implementation.`,
        `<span><h4 class="inline">Brand Performance:</h4></span>- We collect feedback from the target audience and monitor KPIs to measure your brand's performance..`,
      ],
      para2: [
        `We aim to offer end-to-end branding solutions that communicate with your users, create an impact, and stand out from competitors.`,
      ],
    },
    {
      para: [
        `The timeline for the result of our creative branding services depends on various factors, such as the size and scope of the project, market conditions, and the level of brand awareness needed.`,
        `While you can see the immediate effects in terms of customer engagement, the result of full impact takes time.`,
        `Brand building is a continuous process, and the results are evident as your customer base increases, brand awareness grows, competitors start researching your brand, etc.`,
      ],
    },
    {
      para: [
        `We evaluate the success of our branding efforts through qualitative and quantitative measures. We assess brand awareness through surveys, customer feedback, market research, etc.`,
        `We also analyze KPIs such as conversion rates, customer engagement, impressions, website visitors, etc.`,
        `Additionally, we gather stakeholders' feedback to ensure that our services align with their expectations and brand values. We focus on offering branding services that effectively contribute to your business's success.`,
      ],
    },

    {
      para: [
        `Yes, we believe in long-term partnerships and offer support post-project launch to ensure our client's continued success and growth.`,
        `As one of the best branding companies, we understand that ongoing support is essential for brand consistency and keeping up with market dynamics.`,
      ],
    },
    {
      para: [
        `The cost of our branding services depends on your business requirements. Every business or project is different and comes with unique challenges, complex designs, timelines, etc.`,
        `We believe in transparency and offer you a detailed quotation before starting our long-term partnership. However, we would encourage you to kindly <a title='contact us' href='/contact-us/' target="_blank" rel="noreferrer">contact us</a> for accurate cost and consultation.`,
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "1. How can your UI UX design promote our brand?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Being a leading UI UX design agency, we create a visually appealing and user-friendly interface that aligns with your brand and connects with your target audience. 
            
            We focus on creating thoughtful designs that resonate with your audience, establish your digital presence in the market, and distinguish you from your competitors. 
            
            Additionally, we consider offering a positive, seamless, and intuitive user experience to enhance your brand perception among users and foster customer loyalty and trust, ultimately leading to long-term business success."
                }
              },{
                "@type": "Question",
                "name": "2. What are the branding services that you can offer?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We offer various digital branding services tailored to meet your business requirements. Our services include-
            
            Research & Insights
            We start by listening to all the stakeholders and charting out insight-driven strategies.
            Positioning & Proposition
            We brainstorm various ideas as to how we can position your brand to stand out from the competition.
            Brand Messaging
            We define clear rules and guidelines for brand voice & tone and help in its implementation.
            Brand Performance
            We collect feedback from the target audience and monitor KPIs to measure your brand's performance.
            
            We aim to offer end-to-end branding solutions that communicate with your users, create an impact, and stand out from competitors."
                }
              },{
                "@type": "Question",
                "name": "3. When does your branding effort start delivering results?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The timeline for the result of our creative branding services depends on various factors, such as the size and scope of the project, market conditions, and the level of brand awareness needed. 	
            
            While you can see the immediate effects in terms of customer engagement, the result of full impact takes time. 
            
            Brand building is a continuous process, and the results are evident as your customer base increases, brand awareness grows, competitors start researching your brand, etc."
                }
              },{
                "@type": "Question",
                "name": "4. How do you evaluate the success of your branding services?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We evaluate the success of our branding efforts through qualitative and quantitative measures. We assess brand awareness through surveys, customer feedback, market research, etc. 
            
            We also analyze KPIs such as conversion rates, customer engagement, impressions, website visitors, etc. 
            
            Additionally, we gather stakeholders' feedback to ensure that our services align with their expectations and brand values. We focus on offering branding services that effectively contribute to your business's success."
                }
              },{
                "@type": "Question",
                "name": "5. Do you provide post-project support?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, we believe in long-term partnerships and offer support post-project launch to ensure our client's continued success and growth. 
            
            As one of the best branding companies, we understand that ongoing support is essential for brand consistency and keeping up with market dynamics."
                }
              },{
                "@type": "Question",
                "name": "6. What is the cost of opting for your Branding Services?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The cost of our branding services depends on your business requirements. Every business or project is different and comes with unique challenges, complex designs, timelines, etc. 
            
            We believe in transparency and offer you a detailed quotation before starting our long-term partnership. However, we would encourage you to kindly contact us for accurate cost and consultation."
                }
              }]
            }          
          `}
        </script>
      </Helmet>
      <Layout>
        <div className="service-detail-page">
          <Banner content={banner} page={"service"} />
          <CommonServiceComponent data={commonContent6} mode={"grey"} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent} mode={"light"} />
          <Cta mode="grey" data={cta4} />
          <CommonServiceComponent data={commonContent2} mode={"dark"} />
          <CommonServiceComponent data={commonContent3} mode={"light"} />
          <Cta data={cta} />
          <Clients title={clientsTitle} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent4} mode={"light"} />
          <Cta mode="grey" data={cta5} />
          <section className="project-section lg:py-[200px] md:py-[120px] py-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
            <div className="container">
              <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36]">
                Explore Our Branding  <span className="h1-span">Agency’s Work</span>
              </h2>
            </div>
            {projectData.map((data, index) => (
              <div key={index}>
                <ProjectSection
                  num={index}
                  lastLength={projectData}
                  data={data}
                />
              </div>
            ))}
          </section>
          <Testimonials title={"Clients Review on Our <span class='h1-span'>Branding Services</span>"} />
          <Cta data={cta2} />
          <CommonServiceComponent
            data={commonContent5}
            condition
            mode={"light"}
          />
          <ServiceTab tabs={tabs} mode={"split"} />
          <Faq
            Questions={Questions}
            section
            des={faqDes}
            link
            faqData={faqData}
          />
          <Cta mode="grey" data={cta3} />
          {/* <KnowMoreSection /> */}
        </div>
      </Layout>
    </>
  );
};

export default ServiceDetail;

export const Head = () => (
  <Seo
    title="Branding Agency | Creative Branding Agency"
    description="Elevate your brand with our full-service branding company. We offer comprehensive branding services and craft unique, impactful brand identities."
  />
);
